import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import axiosInstance from '../api';


import './FullList.css';
import { Container, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Material UI Icons
import TodayIcon from '@mui/icons-material/Today';
import WorkIcon from '@mui/icons-material/Work';
import RoomIcon from '@mui/icons-material/Room';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PaidIcon from '@mui/icons-material/Paid';
import NumbersIcon from '@mui/icons-material/Numbers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PaymentsIcon from '@mui/icons-material/Payments';

// Individual components
import JobDetails from '../components/JobDetails';
import Chip from '@mui/material/Chip';
import PdfPreview from '../components/PDFPreview';
import FilterComponent from '../components/FilterComponent';
import ErrorPage from '../components/ErrorPage';


const FullList = () => {
  // State variables
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [activeCity, setActiveCity] = useState('Denver');
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [workers, setWorkers] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const detailViewRef = useRef(null);

  const cities = ['Denver', 'Las Vegas', 'Phoenix'];

  // Debounce function
  const debounce = (func, delay) => {
  let timeout;

  return (...args) => {
    // Clear the previous timeout
    clearTimeout(timeout); 
    // Set a new timeout
    timeout = setTimeout(() => func(...args), delay);
  };
  };

  // Search component
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  // Debounced version of setFilterText
  const debouncedSetFilterText = React.useMemo(
    () => debounce((text) => setFilterText(text), 200),
    [] 
  );
  
  const filteredItems = tableData.filter((item) => {
    const searchText = filterText.toLowerCase();
    return (
      (item.description && item.description.toLowerCase().includes(searchText)) ||
      (item.job_order_number && item.job_order_number.toString().toLowerCase().includes(searchText)) ||
      (item.address && item.address.toLowerCase().includes(searchText))
    );
  });

  
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <p style={{ color: '#111111B2' }}><WorkIcon /> Job Description</p>,
      selector: row => `${row.description}`,
      sortable: true,
      cell: row => (
        <span className="highlight-description fake-data">{row.description}</span>
      ),
      sortFunction: (a, b) => {
        // Function to sum quantities in job_items
        const getTotalQuantity = (job) => {
          return job.job_items.reduce((sum, item) => sum + item.quantity, 0);
        };

        // Calculate total quantities for each job
        const totalA = getTotalQuantity(a);
        const totalB = getTotalQuantity(b);

        // Compare totals for sorting
        return totalA - totalB;
      },
      width: "auto", // flexible minimum width for the column
    },

    {
      name: <p style={{ color: '#111111B2' }}><PaymentsIcon sx={{ color: '#111111B2' }} /> Total</p>,
      selector: row => row.total_cost,
      sortable: true,
      width: "auto", // flexible minimum width for the column

    },

    {
      name: <p style={{ color: '#111111B2' }}><RoomIcon /> Address</p>,
      selector: row => row.address,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: '100%' }}>
          <span className='fake-data'>{row.address}</span>
          <span className="ms-2" style={{ background: '#E5E5EA', padding: '5px 7px', borderRadius: '10px' }}>
            <PdfPreview pdfUrl='./Westfield_3x2_1218_SF_Waterford_Place_Apartments.pdf' />
          </span>
        </div>
      ),
      width: "250px", // flexible minimum width for the column
    },

    {
      name: <p style={{ color: '#111111B2' }}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.436 3.40972C1.19209e-07 3.90572 0 4.75772 0 6.45972V15.8537C0 17.0597 5.96046e-08 17.6627 0.315 18.1167C0.629 18.5707 1.179 18.7617 2.279 19.1427L3.573 19.5917C4.274 19.8347 4.813 20.0217 5.266 20.1417C5.562 20.2207 5.833 19.9887 5.833 19.6827V4.53272C5.82954 4.41127 5.78597 4.29439 5.70908 4.20032C5.6322 4.10625 5.52633 4.04028 5.408 4.01272C5.019 3.91372 4.551 3.75172 3.91 3.52972C2.357 2.99072 1.58 2.72172 0.99 2.99472C0.778826 3.09359 0.590251 3.23485 0.436 3.40972ZM10.62 1.74372L9.084 2.80872C8.529 3.19372 8.121 3.47672 7.774 3.67772C7.69257 3.72378 7.6245 3.79021 7.57646 3.8705C7.52843 3.95079 7.50208 4.04218 7.5 4.13572V19.1827C7.5 19.5527 7.884 19.7847 8.196 19.5847C8.531 19.3707 8.915 19.1047 9.38 18.7827L10.916 17.7177C11.471 17.3327 11.879 17.0497 12.226 16.8487C12.3074 16.8027 12.3755 16.7362 12.4235 16.6559C12.4716 16.5756 12.4979 16.4843 12.5 16.3907V1.34272C12.5 0.971716 12.116 0.740716 11.804 0.939716C11.469 1.15472 11.085 1.42072 10.62 1.74372ZM17.72 1.38272L16.427 0.934716C15.726 0.691716 15.187 0.504716 14.734 0.384716C14.438 0.305716 14.167 0.537716 14.167 0.843716V15.9937C14.1705 16.1152 14.214 16.232 14.2909 16.3261C14.3678 16.4202 14.4737 16.4862 14.592 16.5137C14.981 16.6127 15.449 16.7737 16.09 16.9967C17.643 17.5357 18.42 17.8047 19.01 17.5317C19.2212 17.4328 19.4097 17.2916 19.564 17.1167C20 16.6207 20 15.7687 20 14.0667V4.67272C20 3.46672 20 2.86272 19.685 2.40972C19.371 1.95572 18.821 1.76472 17.721 1.38372" fill="#111111" fillOpacity="0.7" />
      </svg>  Zip Code</p>,
      selector: row => row.zip_code,
      cell: row => (
        <span className='fake-data'>{row.zip_code}</span>
      ),
      sortable: true,
    },

    {
      name: <p style={{ color: '#111111B2' }}><LocalOfferIcon /> Place Name</p>,
      selector: row => row.place_name,
      cell: row => (
        <span className='fake-data'>{row.place_name}</span>
      ),
      sortable: true,
    },
    {
      name: <p style={{ color: '#111111B2' }}><CalendarMonthIcon /> Date Requested</p>,
      selector: row => row.date_requested,
      cell: row => (
        <span className='fake-data'>{row.date_requested}</span>
      ),
      sortable: true,
    },
    {
      name: <p style={{ color: '#111111B2' }}> <EngineeringIcon /> Worker</p>,
      selector: row => row.worker_id,
      sortable: true,
      cell: row => (
        <div className='fake-data' style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: '100%' }}>
          <FormControl sx={{ flexGrow: 1 }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={row.worker_id ? row.worker_id : 'None'} 
              className='fake-data'
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#1D1B2014",
                width: "auto",
                minWidth: "80px", // set minimum width
                maxWidth: "300px", // set maximum width for longer names
                marginRight: "10px",
                height: "40px",
                textAlign: "left",
              }}
              onChange={(e) => handleWorkerChange(e, row)}
            >
              <MenuItem value={row.worker?.id ? row.worker?.id : 'None'}>{row.worker?.name ? row.worker?.name : 'None'}</MenuItem>

              {workers.map((worker, index) => (
                <MenuItem key={index} value={worker.worker_id}>{worker.name}</MenuItem>
              ))}
            </Select>

          </FormControl>
        </div>
      ),
      width: "auto", // flexible minimum width for the column    
    },
    {
      name: <p style={{ color: '#111111B2' }}><PaidIcon /> Payment</p>,
      selector: row => row.payment_status,
      sortable: true,
      cell: row => (
        <span className='fake-data'>
          {row.payment_status ? (
            <Chip className='fake-data' sx={{ background: "#4A44591F" }} icon={<CheckCircleIcon />} label='Paid' />
          ) : (
            <Chip className='fake-data' sx={{ background: "#dadae1" }} icon={<CancelIcon sx={{ color: '#FFF', fontFamily: 'Comic Sans MS' }} color='info' />} label='Pending' />
          )}
        </span>
      ),
    },

    {
      name: <p style={{ color: '#111111B2' }}><NumbersIcon/> Job Order</p>,
      selector: row => row.job_order_number,
      sortable: true,
      cell: row => (
        <span className='fake-data'>{row.job_order_number}</span>
      ),
    },
    {
      name: 'Total Count',
      selector: row => row.counts.reduce((sum, count) => sum + count, 0),
      sortable: true,
      omit: true // Hide this column in the table, but it can be used for sorting
    }


  ];

  // Handle date change, this will be used to filter the data in the future
  const handleDateChange = date => {
    if (!date) {
      setSelectedDate(null);
      setFormattedDate('Select Date');
      setOpenDatePicker(false);
      return;
    }

    // Adjust date to local time zone
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    // Convert to YYYY-MM-DD format
    const ISODate = localDate.toISOString().split('T')[0];
    setSelectedDate(ISODate);
    setFormattedDate(date ? date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }) : '');
    setOpenDatePicker(false);
  };

  // Handle row click
  const handleRowClick = row => {
    setSelectedRow(row);
  };

  // Close detail view
  const closeDetailView = () => {
    setSelectedRow(null);
  };


  // Handle worker change and update the selected row in table data
  let debounceTimeout;
  const handleWorkerChange = (e, row) => {
    let worker_id = e.target.value;
    
    if (worker_id === 'None') {
      worker_id = null;
    }

    // Update tableData
    const updatedTableData = tableData.map(r =>
      r.job_order_number === row.job_order_number
        ? { ...r, worker_id: worker_id } 
        : r // Keep other rows unchanged
    );

    setTableData(updatedTableData); 

    // Update selected row in the open detail view
    if (row.job_order_number === selectedRow?.job_order_number) {
      setSelectedRow({ ...selectedRow, worker_id: worker_id });
    }

    // Debounced API save
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      saveWorkerData(row.job_id, worker_id);
    }, 500);

  };

  // Function to save worker data to the API
  const saveWorkerData = async (jobId, worker_id) => {
    try {
      await axiosInstance.put(`/api/jobs/${jobId}/assign`, null, {
      params: { worker_id: worker_id},
      headers: { Accept: 'application/json' },
    });
      console.log('Worker data saved successfully');
    } catch (error) {
      console.error('Failed to save worker data:', error);
    }
  };

  // Function to detect clicks outside the modal
  const handleClickOutside = (event) => {
    if (detailViewRef.current && !detailViewRef.current.contains(event.target)) {
      closeDetailView();
    }
  };


  // Fetch data from API
  useEffect(() => {
    const fetchJobsAndWorkers = async (activeCity, selectedDate) => {
      setLoading(true); // Start loading
      try {
        // Build query parameters dynamically
        const params = new URLSearchParams();
        if (activeCity) params.append("warehouse", activeCity);
        if (selectedDate) params.append("date_requested", selectedDate);

        // Fetch job data
        let jobsResponse = await axiosInstance.get(`/api/jobs?${params.toString()}`);
        let jobs = jobsResponse.data?.jobs;
        let date_requested = jobsResponse.data?.date_requested ? jobsResponse.data?.date_requested : selectedDate;

        console.log("jobsResponse", jobsResponse);
        console.log(localStorage.getItem("accessToken"))

        // Update table data
        setTableData(jobs);

        // Update formatted date
        setFormattedDate(date_requested ? new Date(date_requested).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }) : '');


        // Fetch workers data
        let workersResponse = await axiosInstance.get(`/api/workers?${params.toString()}`);
        setWorkers(workersResponse.data);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    // Check which parameters are available
    if (activeCity || selectedDate) {
      console.log("Re-running fetchJobsAndWorkers");
      fetchJobsAndWorkers(activeCity, selectedDate);
    }
  }, [activeCity, selectedDate]); // Re-run when activeCity or selectedDate changes


  // useEffect to add/remove event listener
  useEffect(() => {
    if (selectedRow) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedRow], [handleClickOutside]);


  // Display loading or error state
  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage mainHeading="Oops.." subHeading={`There was an error fetching the data: ${error}`} />;


  return (

    <Container sx={{ pl: 0 }} maxWidth={false} disableGutters   // Removes default maxWidth constraint
    >
      {/* Filter options */}
      <Container maxWidth={false}   // Removes default maxWidth constraint
        disableGutters sx={{ mb: 1, background: "#F2F2F7" }} className='ps-0'>

        <Stack direction="row" sx={{ py: 2, pl: 0, ml: '100px', background: "#F2F2F7" }}>

          {/* Date Picker Button */}
          <button
            className="btn"
            onClick={() => setOpenDatePicker(!openDatePicker)}
            style={{
              background: '#B1B1B1',
              border: 'none',
              borderRadius: '10px',
              color: '#FFF',
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <TodayIcon style={{ marginRight: '8px', color: '#FFF' }} />
            <strong>{formattedDate || 'Select Date'}</strong>
          </button>

          {openDatePicker && (

            <div style={{ position: 'absolute', textAlign: 'center', zIndex: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', top: '65px' }}>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd MMM yyyy"
                inline
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                }) => (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 10px',
                    alignItems: 'center',
                  }}>
                    <button onClick={decreaseMonth} style={{ background: 'none', border: 'none' }}>
                      &lt;
                    </button>
                    <span style={{ fontWeight: 'bold', color: '#333' }}>
                      {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                    </span>
                    <button onClick={increaseMonth} style={{ background: 'none', border: 'none' }}>
                      &gt;
                    </button>
                  </div>
                )}
              />
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: '1px solid #e0e0e0',
              }}>
            
              </div>
            </div>
          )}


          <div>
            {cities.map((city, index) => (
              <button
                key={index}
                className={`btn ${activeCity === city ? 'active-city' : ''} ms-5`}
                onClick={() => setActiveCity(city)}
              >
                {city}
              </button>
            ))}
          </div>
        </Stack>
      </Container>

      {/* Table */}
      <Container sx={{ mb: 5, background: 'white', py: 4, pl: '100px' }} maxWidth={false}
        disableGutters
      >

        <DataTable
          columns={columns}
          data={filteredItems}
          onRowClicked={handleRowClick}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          highlightOnHover
          striped
          responsive
          conditionalRowStyles={
            [{
              when: row => selectedRow && row.job_id === selectedRow.job_id,
              style: {
                backgroundColor: '#EEEEEE',
              },
            }]
          }
          customStyles={{
            rows: {
              style: {
                padding: '18px 0',

              }
            },
          }}
        />

        {/* Detail view */}
        {selectedRow && (
          <div className="detail-view" ref={detailViewRef}>
            <div className="d-flex justify-content-end mb-4">
              <button onClick={closeDetailView} className="btn btn-secondary btn-close" aria-label="Close"></button>
            </div>

            <div className="container">
              <div className="row align-items-center px-5">

                <div className="col firstDiv">

                  <div className="fw-bold mb-5">
                    <p><NumbersIcon sx={{ color: '#111111B2' }} /> Job Order</p>
                    <p className='ps-2 fake-data'>{selectedRow.job_order_number}</p>
                  </div>

                  <div className="fw-bold mb-5">
                    <p><CalendarMonthIcon sx={{ color: '#111111B2' }} /> Date Requested</p>
                    <p className='ps-2 fake-data'>{selectedRow.date_requested}</p>
                  </div>

                  <div className="fw-bold">
                    <p><WorkIcon sx={{ color: '#111111B2' }} /> Job Description</p>
                    <p className='ps-2 fake-data'>{selectedRow.description}</p>
                  </div>
                </div>


                <div className="col secondDiv">

                  <div className="fw-bold mb-5">
                    <div className='d-flex align-items-center'>
                      <EngineeringIcon sx={{ color: '#111111B2', marginRight: 1 }} />

                      <select
                        className="form-select ps-2 w-50 fake-data"
                        value={selectedRow.worker_id || ''} 
                        onChange={(e) => handleWorkerChange(e, selectedRow)}
                        style={{
                          backgroundColor: '#1D1B2014',
                          border: 'none',
                          color: '#111111B2',
                          height: '45px',
                          padding: '8px 10px',
                          width: 'auto',
                        }}
                      >
                        <option value="None">Select a worker</option> {/* Default option */}
                        {workers.map((worker, index) => (
                          <option key={index} value={worker.worker_id}>
                            {worker.name}
                          </option>
                        ))}
                      </select>


                    </div>
                  </div>

                  <div className="fw-bold mb-5">
                    <p><LocalOfferIcon sx={{ color: '#111111B2' }} />  Place Name</p>
                    <p className='ps-2'>{selectedRow.place_name}</p>
                  </div>

                  <div className="fw-bold">
                    <p><RoomIcon sx={{ color: '#111111B2' }} /> Address</p>
                    <p className='ps-2'>{selectedRow.address}</p>
                  </div>
                </div>
              </div>


              <div>
                <JobDetails jobItems={selectedRow.job_items || []} jobNotes={selectedRow.job_notes || []} />
              </div>
            </div>
          </div>
        )}

      </Container>
    </Container>
  );
};

export default FullList;
