import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/List';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import { Box, IconButton, Container, Typography } from '@mui/material';
import Workers from './pages/Workers';
import Login from './pages/Login';
import FullList from './pages/FullList';
import { handleLogout } from './utils';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorPage from './components/ErrorPage';


const Sidebar = () => {
  const location = useLocation();

  if (location.pathname === '/login') return null; // Don't render on login page

  return (
    <Box component="nav" className="sidebar">
      <ul className="sidebar-list">
        <li className="sidebar-item my-4 mb-5">
          <IconButton color="inherit">
            <MenuIcon />
          </IconButton>
        </li>
        <li className="sidebar-item">
          <Link to="/" className="sidebar-link">
            <div className="sidebar-icon mb-1">
              <ListIcon />
            </div>
            Full List
          </Link>
        </li>


        <li className="sidebar-item">
          <Link to="/workers" className="sidebar-link">
            <div className="sidebar-icon mb-1">
              <GroupsOutlinedIcon />
            </div>
            Workers
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/jobs" className="sidebar-link">
            <div className="sidebar-icon mb-1">
              <ScheduleOutlinedIcon />
            </div>
            Jobs
          </Link>
        </li>

        <hr className="sidebar-divider" />
        <li className="sidebar-item">
          <Link to="/login" className="sidebar-link" onClick={handleLogout}>
            <div className="sidebar-icon mb-1">
              <LogoutIcon />
            </div>
            Logout
          </Link>
        </li>
      </ul>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <Sidebar />
      <Routes>
        <Route path="/" element=
          {<ProtectedRoute>
            <FullList />
          </ProtectedRoute>} />

        <Route path="/workers" element=

          {
            <ProtectedRoute>
              <Workers />
            </ProtectedRoute>

          }

        />
        <Route path="/jobs" element={<Container fixed>
          <Typography variant="h2" gutterBottom className="custom-title">Jobs</Typography>
        </Container>} />
        <Route path="/login" element={<Login />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
