import React, { useState, useRef, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';


import './FullList.css';
import { Container, IconButton, Stack, ThemeProvider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListIcon from '@mui/icons-material/List';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Material UI Icons
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddIcon from '@mui/icons-material/Add';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentsIcon from '@mui/icons-material/Payments';
import { createTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';


// Individual components
import NotePreview from '../components/NotePreview';
import FilterComponent from '../components/FilterComponent';

import axiosInstance from '../api';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ErrorPage from '../components/ErrorPage';


const Workers = () => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  // API URL
  const API_URL = process.env.REACT_APP_API_URL;

  // Form state
  const [formName, setName] = useState('');
  const [formContactInfo, setContactInfo] = useState('');
  const [formAddress, setAddress] = useState('');
  const [formCity, setCity] = useState('');
  const [formState, setState] = useState('');
  const [formZipCode, setZipCode] = useState('');
  const [formWorkLocation, setWorkLocation] = useState('');
  const [formNotes, setNotes] = useState('');
  const [workerId, setWorkerId] = useState(null);

  // State variables
  const [activeCity, setActiveCity] = useState('Denver');
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const detailViewRef = useRef(null);

  const cities = ['Denver', 'Las Vegas', 'Phoenix'];

  const [activeTab, setActiveTab] = useState('successful');

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState(null);

  // Edit
  const isEdit = !!workerId;



  const theme = createTheme({
    palette: {
      customGray: {
        main: '#49454F',
      },
    },
  });



  const handleOpenModal = useCallback((worker_id = null, isEdit = false) => {
    console.log('Opening modal...');

    if (worker_id && isEdit) {
      // Close the selected row
      setSelectedRow(null);

      console.log('Worker ID provided:', worker_id);
      // Find the worker by ID
      const worker = tableData.find(worker => worker.worker_id === worker_id);
      console.log('Edit Worker:', worker);

      // Set the form values
      setName(worker.name);
      setContactInfo(worker.contact_info);
      setAddress(worker.address);
      setCity(worker.city);
      setState(worker.state);
      setZipCode(worker.zip_code);
      setWorkLocation(worker.warehouse);
      setNotes(worker.notes ? worker.notes : '');

      // Set the worker ID
      setWorkerId(worker_id);
    }
    else {
      // Reset form values
      setName('');
      setContactInfo('');
      setAddress('');
      setCity('');
      setState('');
      setZipCode('');
      setWorkLocation('');
      setNotes('');

      // Reset worker ID
      setWorkerId(null);
    }

    setIsModalOpen(true);


  }, [tableData]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  // Search component
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = tableData.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
        {/* Left Button */}
        <button style={{
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '50px',
          padding: '10px 20px',
          marginLeft: '0px',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
          cursor: 'pointer',
          fontWeight: '500',
        }}
          onClick={handleOpenModal}
        >
          <AddIcon sx={{ color: '#111111B2' }} />
          Add New Worker
        </button>

        {/* Right Filter Component */}
        <FilterComponent
          onFilter={e => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );


  }, [filterText, resetPaginationToggle, handleOpenModal]);

  // Data Table
  const columns = [
    {
      name: <p style={{ color: '#111111B2' }}><EngineeringIcon /> Worker</p>,
      selector: row => `${row.name}`,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '80%' }}>
          <span>{row.name}</span>

          <span className="ms-2" style={{ background: '#E5E5EA', padding: '5px 7px', borderRadius: '10px' }}>
            <NotePreview notes={row.notes ? row.notes : 'No notes available'} />
          </span>

        </div>
      ),
    },
    {
      name: <p style={{ color: '#111111B2' }}><PhoneIcon />Phone Number</p>,
      selector: row => row.contact_info,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: '100%' }}>
          <span>{row.contact_info}</span>
        </div>
      ),
    },

    {
      name: <p style={{ color: '#111111B2' }}><RoomIcon />  Address</p>,
      selector: row => row.address,
      cell: row => (
        <span>{row.address}</span>
      ),
      sortable: true,
    },

    {
      name: <p style={{ color: '#111111B2' }}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.436 3.40972C1.19209e-07 3.90572 0 4.75772 0 6.45972V15.8537C0 17.0597 5.96046e-08 17.6627 0.315 18.1167C0.629 18.5707 1.179 18.7617 2.279 19.1427L3.573 19.5917C4.274 19.8347 4.813 20.0217 5.266 20.1417C5.562 20.2207 5.833 19.9887 5.833 19.6827V4.53272C5.82954 4.41127 5.78597 4.29439 5.70908 4.20032C5.6322 4.10625 5.52633 4.04028 5.408 4.01272C5.019 3.91372 4.551 3.75172 3.91 3.52972C2.357 2.99072 1.58 2.72172 0.99 2.99472C0.778826 3.09359 0.590251 3.23485 0.436 3.40972ZM10.62 1.74372L9.084 2.80872C8.529 3.19372 8.121 3.47672 7.774 3.67772C7.69257 3.72378 7.6245 3.79021 7.57646 3.8705C7.52843 3.95079 7.50208 4.04218 7.5 4.13572V19.1827C7.5 19.5527 7.884 19.7847 8.196 19.5847C8.531 19.3707 8.915 19.1047 9.38 18.7827L10.916 17.7177C11.471 17.3327 11.879 17.0497 12.226 16.8487C12.3074 16.8027 12.3755 16.7362 12.4235 16.6559C12.4716 16.5756 12.4979 16.4843 12.5 16.3907V1.34272C12.5 0.971716 12.116 0.740716 11.804 0.939716C11.469 1.15472 11.085 1.42072 10.62 1.74372ZM17.72 1.38272L16.427 0.934716C15.726 0.691716 15.187 0.504716 14.734 0.384716C14.438 0.305716 14.167 0.537716 14.167 0.843716V15.9937C14.1705 16.1152 14.214 16.232 14.2909 16.3261C14.3678 16.4202 14.4737 16.4862 14.592 16.5137C14.981 16.6127 15.449 16.7737 16.09 16.9967C17.643 17.5357 18.42 17.8047 19.01 17.5317C19.2212 17.4328 19.4097 17.2916 19.564 17.1167C20 16.6207 20 15.7687 20 14.0667V4.67272C20 3.46672 20 2.86272 19.685 2.40972C19.371 1.95572 18.821 1.76472 17.721 1.38372" fill="#111111" fillOpacity="0.7" />
      </svg>  Zip Code</p>,
      selector: row => row.zip_code,
      cell: row => (
        <span>{row.zip_code}</span>
      ),
      sortable: true,
    },

    {
      name: <p style={{ color: '#111111B2' }}><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2192 3.66289H14.5968V1.03889C14.5968 0.886126 14.5361 0.739618 14.4281 0.631597C14.3201 0.523576 14.1736 0.462891 14.0208 0.462891H13.5744C13.4216 0.462891 13.2751 0.523576 13.1671 0.631597C13.0591 0.739618 12.9984 0.886126 12.9984 1.03889V3.66289H10.376C10.2232 3.66289 10.0767 3.72358 9.96872 3.8316C9.8607 3.93962 9.80001 4.08613 9.80001 4.23889V13.2597H3.97761C3.65761 13.2597 3.39681 13.5205 3.39681 13.8405V17.4805C3.39681 17.8005 3.65761 18.0597 3.97761 18.0597H17.2176C17.5376 18.0597 17.7936 17.8005 17.7936 17.4821V4.23889C17.7936 4.08613 17.7329 3.93962 17.6249 3.8316C17.5169 3.72358 17.3704 3.66289 17.2176 3.66289M6.59681 16.4597H4.99681V14.8597H6.59681V16.4597ZM9.79681 16.4597H8.19681V14.8597H9.79681V16.4597ZM12.9968 16.4597H11.3968V14.8597H12.9968V16.4597ZM12.9968 13.2597H11.3968V11.6597H12.9968V13.2597ZM12.9968 10.0597H11.3968V8.45969H12.9968V10.0597ZM12.9968 6.85969H11.3968V5.25969H12.9968V6.85969ZM16.1968 16.4597H14.5968V14.8597H16.1968V16.4597ZM16.1968 13.2597H14.5968V11.6597H16.1968V13.2597ZM16.1968 10.0597H14.5968V8.45969H16.1968V10.0597ZM16.1968 6.85969H14.5968V5.25969H16.1968V6.85969ZM8.19681 1.04049C8.19702 0.964715 8.18228 0.889642 8.15343 0.819574C8.12457 0.749505 8.08218 0.685819 8.02867 0.632162C7.97517 0.578506 7.9116 0.535935 7.84161 0.506889C7.77162 0.477842 7.69659 0.46289 7.62081 0.462891H3.97601C3.82325 0.462891 3.67674 0.523576 3.56872 0.631597C3.4607 0.739618 3.40001 0.886126 3.40001 1.03889V3.66289H0.776012C0.623247 3.66289 0.47674 3.72358 0.368719 3.8316C0.260698 3.93962 0.200012 4.08613 0.200012 4.23889V17.4981C0.200012 17.8085 0.451212 18.0581 0.760012 18.0581H1.80001V11.6629H8.19841L8.19681 1.04049ZM3.40001 10.0597H1.80001V8.45969H3.40001V10.0597ZM3.40001 6.85969H1.80001V5.25969H3.40001V6.85969ZM6.60001 10.0597H5.00001V8.45969H6.60001V10.0597ZM6.60001 6.85969H5.00001V5.25969H6.60001V6.85969ZM6.60001 3.65969H5.00001V2.06289H6.60001V3.66289V3.65969Z" fill="#111111" fillOpacity="0.7" />
      </svg> City</p>,
      selector: row => row.warehouse,
      cell: row => (
        <span>{row.warehouse}</span>
      ),
      sortable: true,
    },

    {
      name: <p style={{ color: '#111111B2' }}><PaymentsIcon />  Total</p>,
      selector: row => row.address,
      cell: row => (
        <span>{row.total_paid}</span>
      ),
      sortable: true,
    },

    {
      name: <p style={{ color: '#111111B2' }}><MonetizationOnIcon />   Pending Payment</p>,
      selector: row => row.address,
      cell: row => (
        <span>{row.pending_payment_total}</span>
      ),
      sortable: true,
    },
  ];

  // Handlers
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Regular expressions for form validation
    const usPhoneRegex = /^\(?([0-9]{3})\)?[-.●\s]?([0-9]{3})[-.●\s]?([0-9]{4})$/;
    const zipCodeRegex = /^\d{5}(-\d{4})?$/;
    const cityStateRegex = /^[a-zA-Z\s-]+$/; // Allows letters, spaces, and hyphens
    const usAddressRegex = /^\d+\s[a-zA-Z0-9\s.,'-/#]+$/; // US address regex


    // Validate the form fields
    if (!formName || !formContactInfo || !formAddress || !formZipCode || !formWorkLocation || !formCity || !formState) {
      setModalStatus({
        status: 'error',
        message: 'Please fill in all required fields.',
      });
      return;
    }

    // Validate phone number
    if (!usPhoneRegex.test(formContactInfo)) {
      setModalStatus({
        status: 'error',
        message: 'Invalid phone number. Please enter a valid US phone number of the format (123) 456-7890.',
      });
      return;
    }

    // Validate address
    if (!usAddressRegex.test(formAddress)) {
      setModalStatus({
        status: 'error',
        message: 'Invalid address. The address must start with a house/building number, followed by a street name (e.g., 123 Main St). Avoid special characters like ! or @.',
      });
      return;
    }

    // Validate city
    if (!cityStateRegex.test(formCity)) {
      setModalStatus({
        status: 'error',
        message: 'Invalid city name. Please enter a valid city.',
      });
      return;
    }

    // Validate state
    if (!cityStateRegex.test(formState)) {
      setModalStatus({
        status: 'error',
        message: 'Invalid state name. Please enter a valid state.',
      });
      return;
    }

    // Validate ZIP code
    if (!zipCodeRegex.test(formZipCode)) {
      setModalStatus({
        status: 'error',
        message: 'Invalid ZIP code. Please enter a valid US ZIP code.',
      });
      return;
    }

    // Clear previous error messages
    setModalStatus(null);

    // Create the data object
    const formData = {
      name: formName,
      contact_info: formContactInfo,
      address: formAddress,
      city: formCity,
      state: formState,
      zip_code: formZipCode,
      warehouse: formWorkLocation,
      notes: formNotes,
      archived: false,
    };

    console.log(`${isEdit ? 'Updating' : 'Adding'} Worker:`, formData);

    try {
      if (isEdit) {
        // Edit logic
        await axiosInstance.put(`${process.env.REACT_APP_API_URL}/api/workers/${workerId}`, formData);
        alert('Worker updated successfully');

        // Update the worker in the state if the warehouse matches the active city
        if (formWorkLocation === activeCity) {
        setTableData((prevData) =>
          prevData.map((worker) =>
            worker.worker_id === workerId ? { ...worker, ...formData } : worker
          )
        );
        }
        else {
          // Remove the worker from the table data state if the warehouse doesn't match the active city
          setTableData((prevData) => prevData.filter(worker => worker.worker_id !== workerId));
        }
        
      } else {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/workers`, formData);
        const newWorker = { ...formData, worker_id: response.data.worker_id, total_paid: 0, pending_payment_total: 0 };

        alert('Worker added successfully');

        // Add the new worker to the table data state if the warehouse matches the active city
        if (formWorkLocation === activeCity) {
        setTableData((prevData) => [...prevData, newWorker]);
        }
      }

      // Close the modal after successful submission
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Failed to ${isEdit ? 'update' : 'add'} worker:`, error);
      setModalStatus({
        status: 'error',
        message: `Failed to ${isEdit ? 'update' : 'add'} the worker. Please try again.`,
      });
    }
  };



  // Function to handle the delete action
  const handleDelete = async (worker_id, archive = false) => {
    try {

      // Send DELETE request to the API
      await axiosInstance.delete(`${API_URL}/api/workers/${worker_id}?archive=${archive}`);

      // Remove the deleted worker from the state
      setTableData(prevData => prevData.filter(worker => worker.worker_id !== worker_id));
      // Close the detail view
      setSelectedRow(null);

      alert('Worker deleted successfully');

    } catch (error) {
      console.error('Error deleting worker:', error);
      alert('Failed to delete the worker. Please try again.');
    }
  };

  // Handle row click
  const handleRowClick = row => {
    setSelectedRow(row);

  };

  // Close detail view
  const closeDetailView = useCallback(() => {
    setSelectedRow(null);
  }, [setSelectedRow]);

  // Function to detect clicks outside the modal
  const handleClickOutside = useCallback((event) => {
    if (detailViewRef.current && !detailViewRef.current.contains(event.target)) {
      closeDetailView();
    }
  }, [closeDetailView]);

  // Fetch data from API
  useEffect(() => {
    const fetchWorkers = async (activeCity) => {
      setLoading(true); // Start loading
      try {

        // Fetch workers data
        let workersResponse = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/workers?warehouse=${activeCity}`);
        console.log('Workers Response:', workersResponse.data);
        setTableData(workersResponse.data);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } 
      
      finally {
        setLoading(false); // Stop loading
      }
    };

    if (activeCity) {
      console.log("Re running fetchWorkers");
      fetchWorkers(activeCity);
    }
  }, [activeCity]); // Re-run when activeCity changes

  // useEffect to add/remove event listener
  useEffect(() => {
    if (selectedRow) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedRow, handleClickOutside]);


  // Display loading or error state
  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorPage mainHeading="Oops.." subHeading={`There was an error fetching the data: ${error}`} />;



  return (
    <>
      {/* Conditionally render the modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '75%', bgcolor: 'background.paper', background: '#E5E5EA', borderRadius: '20px', boxShadow: 24, padding: '30px 50px' }}>



          {/* Header Area */}
          <Grid container justifyContent="space-between" alignItems="flex-start" sx={{ mt: 2 }}>
            <Typography
              id="modal-modal-title"
              sx={{ fontWeight: 'bold' }}
              variant="h4"
              component="h2"
              mb={5}
            >
              {isEdit ? 'Edit Worker' : 'Add New Worker'}
            </Typography>
            <IconButton onClick={handleCloseModal} sx={{ fontSize: '0.5rem', backgroundColor: 'white' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Grid>

          {/* Display Error Message */}
          {modalStatus && (
            <Alert severity={modalStatus.status} sx={{ my: 2 }}>
              {modalStatus.message}
            </Alert>
          )}

          {/* Form Area */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Left Section: Form Fields */}
              <Grid size={8}>

                <Grid container spacing={2}>
                  {/* Full Name and Phone Number */}
                  <Grid size={6}>
                    <TextField
                      fullWidth
                      label="Full Name"
                      variant="filled"
                      value={formName}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Type Here"
                      sx={{
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'white',
                          '&:hover': { backgroundColor: 'white' },
                          '&.Mui-focused': { backgroundColor: 'white' },
                        },
                        mb: 3,
                      }}
                    />
                  </Grid>
                  <Grid size={6}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      variant="filled"
                      value={formContactInfo}
                      onChange={(e) => setContactInfo(e.target.value)}
                      placeholder="Type Here"
                      helperText="Format: (123) 456-7890"
                      sx={{
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'white',
                          '&:hover': { backgroundColor: 'white' },
                          '&.Mui-focused': { backgroundColor: 'white' },
                        },
                        mb: 3,
                      }}
                    />
                  </Grid>

                  {/* Address */}
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      variant="filled"
                      value={formAddress}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Type Here"
                      sx={{
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'white',
                          '&:hover': { backgroundColor: 'white' },
                          '&.Mui-focused': { backgroundColor: 'white' },
                        },
                        mb: 3,
                      }}
                    />
                  </Grid>

                  {/* City, State, and Zip Code */}
                  <Grid size={4}>
                    <TextField
                      fullWidth
                      label="City"
                      variant="filled"
                      value={formCity}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="Type Here"
                      sx={{
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'white',
                          '&:hover': { backgroundColor: 'white' },
                          '&.Mui-focused': { backgroundColor: 'white' },
                        },
                        mb: 3,
                      }}
                    />
                  </Grid>
                  <Grid size={4}>
                    <TextField
                      fullWidth
                      label="State"
                      variant="filled"
                      value={formState}
                      onChange={(e) => setState(e.target.value)}
                      placeholder="Type Here"
                      sx={{
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'white',
                          '&:hover': { backgroundColor: 'white' },
                          '&.Mui-focused': { backgroundColor: 'white' },
                        },
                        mb: 3,
                      }}
                    />
                  </Grid>
                  <Grid size={4}>
                    <TextField
                      fullWidth
                      label="Zip Code"
                      variant="filled"
                      value={formZipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      placeholder="Type Here"
                      sx={{
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'white',
                          '&:hover': { backgroundColor: 'white' },
                          '&.Mui-focused': { backgroundColor: 'white' },
                        },
                        mb: 3,
                      }}
                    />
                  </Grid>

                  {/* Work Location */}
                  <Grid size={12}>
                    <FormControl fullWidth>
                      <InputLabel id="warehouse-label" color="primary"
                      sx={{ 
                        transform: formWorkLocation ? 'translate(0, -20px) scale(0.75)' : 'translate(10px, 15px) scale(1)',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                      >
                        <RoomIcon /> Work Location
                      </InputLabel>
                      <Select
                        labelId="warehouse-label"
                        id="warehouse"
                        value={formWorkLocation}
                        onChange={(e) => setWorkLocation(e.target.value)}
                        sx={{ backgroundColor: '#1D1B2014' }}

                      >
                        {cities.map((city, index) => (
                          <MenuItem key={index} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

              </Grid>

              {/* Right Section: Notes */}
              <Grid size={4}>

                <TextField
                  fullWidth
                  label="Notes"
                  multiline
                  rows={13}
                  variant="filled"
                  value={formNotes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Type Here"
                  helperText="Max 2000 characters"
                  slotProps={{ htmlInput: { maxLength: 2000 } }}
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'white',
                      '&:hover': { backgroundColor: 'white' },
                      '&.Mui-focused': { backgroundColor: 'white' },
                    },
                  }}
                />

              </Grid>
            </Grid>

            {/* Action Buttons */}
            <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>

              <button
                style={{
                  backgroundColor: 'white',
                  border: 'none',
                  borderRadius: '50px',
                  padding: '10px 20px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
                  cursor: 'pointer',
                  fontWeight: '500',
                }}
              >
                {isEdit ? 'Update Worker' : 'Add Worker'}
              </button>



              <button
                style={{
                  backgroundColor: 'white',
                  border: 'none',
                  borderRadius: '50px',
                  padding: '10px 20px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
                  cursor: 'pointer',
                  fontWeight: '500',
                }}
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </Grid>

          </form>


        </Box>

      </Modal>

      <Container sx={{ pl: 0 }} maxWidth={false} disableGutters   // Removes default maxWidth constraint
      >
        {/* Filter options */}
        <Container maxWidth={false}   // Removes default maxWidth constraint
          disableGutters sx={{ mb: 1, background: "#F2F2F7" }} className='ps-0'>

          <Stack direction="row" sx={{ py: 2, pl: 0, ml: '100px', background: "#F2F2F7" }}>

            <div>
              {cities.map((city, index) => (
                <button
                  key={index}
                  className={`btn ${activeCity === city ? 'active-city' : ''} ms-5`}
                  onClick={() => setActiveCity(city)}
                >
                  {city}
                </button>
              ))}
            </div>
          </Stack>
        </Container>

        {/* Table */}
        <Container sx={{ mb: 5, background: 'white', py: 4, pl: '100px' }} maxWidth={false}  // Removes default maxWidth constraint
          disableGutters
        >
          <DataTable
            columns={columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            highlightOnHover
            onRowClicked={handleRowClick}
            striped
            responsive
            conditionalRowStyles={
              [{
                when: row => selectedRow && row.worker_id === selectedRow.worker_id,
                style: {
                  backgroundColor: '#EEEEEE',
                },
              }]
            }
            customStyles={{
              rows: {
                style: {
                  padding: '18px 0',
                }
              }
            }}
          />


          {/* Detail view */}
          {selectedRow && (
            <div className="detail-view" ref={detailViewRef}>
              <div className="d-flex justify-content-end mb-4">
                <button onClick={closeDetailView} className="btn btn-secondary btn-close" aria-label="Close"></button>
              </div>

              <div className="container">
                <div className="row align-items-center px-5">
                  
                  <div className="col firstDiv">

                    <div className="fw-bold mb-5">
                      <p><EngineeringIcon sx={{ color: '#111111B2' }} /> Worker Name</p>
                      <p className='ps-2'>{selectedRow.name}</p>
                    </div>

                    <div className="fw-bold mb-5">
                      <p><PaymentsIcon sx={{ color: '#111111B2' }} /> Total Cost</p>
                      <p className='ps-2'>{selectedRow.total_paid}</p>
                    </div>

                    <div className="fw-bold">
                      <p><MonetizationOnIcon sx={{ color: '#111111B2' }} /> Pending Payment</p>
                      <p className='ps-2'>{selectedRow.pending_payment_total}</p>
                    </div>

                  </div>

                  <div className="col secondDiv">
                    
                    <div className="fw-bold mb-5">
                      <p><RoomIcon sx={{ color: '#111111B2' }} /> Address</p>
                      <p className='ps-2'>{selectedRow.address}</p>
                    </div>

                    <div className="fw-bold mb-5">
                      <p><PhoneIcon sx={{ color: '#111111B2' }} />  Phone Number</p>
                      <p className='ps-2'>{selectedRow.contact_info}</p>
                    </div>

                    <div className="fw-bold mb-5">
                      <button
                        style={{
                          backgroundColor: 'white',
                          border: 'none',
                          borderRadius: '50px',
                          padding: '10px 20px',
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
                          cursor: 'pointer',
                          fontWeight: '500',
                          marginRight: '15px'
                        }}
                        onClick={() => handleOpenModal(selectedRow.worker_id, true)}
                      >
                        Edit
                      </button>

                      <button
                        style={{
                          backgroundColor: 'white',
                          border: 'none',
                          borderRadius: '50px',
                          padding: '10px 20px',
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
                          cursor: 'pointer',
                          fontWeight: '500',
                          marginRight: '15px'
                        }}
                        onClick={() => handleDelete(selectedRow.worker_id, true)}
                      >
                        Archive
                      </button>

                      <button
                        style={{
                          backgroundColor: 'white',
                          border: 'none',
                          borderRadius: '50px',
                          padding: '10px 20px',
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
                          cursor: 'pointer',
                          fontWeight: '500',
                        }}
                        onClick={() => handleDelete(selectedRow.worker_id)}
                      >
                        Delete
                      </button>
                    </div>

                  </div>
                </div>

                {/* Worker Details */}
                {/* Notes */}
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 'bold' }}>
                      Notes
                    </Typography>

                    <Typography sx={{ mb: 1.5 }}>{selectedRow.notes ? selectedRow.notes : 'No notes available'}</Typography>

                  </CardContent>

                </Card>


                {/* Jobs by Worker */}
                <Container fixed sx={{ backgroundColor: '#F2F2F7', paddingBottom: 5, mt: 4 }}>
                  {/* Tabs */}
                  <div className="job-tabs">

                    <button
                      className={`tab-item ${activeTab === 'successful' ? 'active' : ''}`}
                      onClick={() => setActiveTab('successful')}>
                      <ListIcon /> Successful Jobs
                    </button>

                    <button
                      className={`tab-item ${activeTab === 'unsuccessful' ? 'active' : ''}`}
                      onClick={() => setActiveTab('unsuccessful')}>
                      <AttachFileIcon /> Incomplete Jobs
                    </button>
                  </div>

                  {activeTab === 'successful' && (

                    <div>

                      <div>

                        {/* If no items are available, show message below */}
                        {selectedRow.successful_jobs?.length === 0 && (
                          <div className="no-items-message">No items available</div>
                        )}


                      </div>

                      {/* Successful Jobs Box */}
                      {selectedRow.successful_jobs?.length > 0 && (


                        <Box className="job-items mt-2"
                          sx={{
                            overflowX: 'auto',
                            display: 'flex',
                            gap: 2,

                          }}
                        >

                          {selectedRow.successful_jobs.map((job, index) => (
                            <Box
                              className="job-item job-item-row bg-white p-3"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: 'max-content'
                              }}
                              key={index}
                            >

                              <TextField
                                id="outlined-helperText"
                                label="Job Number"
                                value={job.job_order_number}
                                disabled
                                sx={{ maxWidth: 100 }}
                              />

                              <TextField
                                id="outlined-helperText"
                                label="Description"
                                value={job.description}
                                disabled
                                sx={{ maxWidth: 150 }}
                              />

                              <TextField
                                id="outlined-helperText"
                                label="Place Name"
                                value={job.place_name}
                                disabled
                                sx={{ maxWidth: 150 }}
                              />

                              <TextField
                                id="outlined-helperText"
                                label="Date Requested"
                                value={job.date_requested}
                                disabled
                                sx={{ maxWidth: 120 }}
                              />

                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: 'none',
                                  borderRadius: '50px',
                                  padding: '10px 20px',
                                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
                                  cursor: 'pointer',
                                  fontWeight: '500',
                                }}
                              >
                                See Job
                              </button>

                            </Box>
                          ))}

                        </Box>
                      )}
                    </div>
                  )}

                  {activeTab === 'unsuccessful' && (
                    <div>

                      <div>

                        {/* If no items are available, show message below */}
                        {selectedRow.incomplete_jobs?.length === 0 && (
                          <div className="no-items-message">No items available</div>
                        )}


                      </div>

                      {/* Incomplete Jobs Box */}
                      {selectedRow.incomplete_jobs?.length > 0 && (


                        <Box className="job-items mt-2"
                          sx={{
                            overflowX: 'auto',
                            display: 'flex',
                            gap: 2,

                          }}
                        >

                          {selectedRow.successful_jobs.map((job, index) => (
                            <Box
                              className="job-item job-item-row bg-white p-3"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: 'max-content'
                              }}
                              key={index}
                            >

                              <TextField
                                id="outlined-helperText"
                                label="Job Number"
                                value={job.job_order_number}
                                disabled
                                sx={{ maxWidth: 100 }}
                              />

                              <TextField
                                id="outlined-helperText"
                                label="Description"
                                value={job.description}
                                disabled
                                sx={{ maxWidth: 150 }}
                              />

                              <TextField
                                id="outlined-helperText"
                                label="Place Name"
                                value={job.place_name}
                                disabled
                                sx={{ maxWidth: 150 }}
                              />

                              <TextField
                                id="outlined-helperText"
                                label="Date Requested"
                                value={job.date_requested}
                                disabled
                                sx={{ maxWidth: 120 }}
                              />

                              <button
                                style={{
                                  backgroundColor: 'white',
                                  border: 'none',
                                  borderRadius: '50px',
                                  padding: '10px 20px',
                                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 1px',
                                  cursor: 'pointer',
                                  fontWeight: '500',
                                }}
                              >
                                See Job
                              </button>

                            </Box>
                          ))}

                        </Box>
                      )}
                    </div>

                  )}
                </Container>


                {/*
              <div>
                <JobDetails jobItems={selectedRow.job_items || []} jobNotes={selectedRow.job_notes || []} />
              </div>
              */}
              </div>
            </div>
          )}


        </Container >
      </Container >
    </>
  );
};

export default Workers;
