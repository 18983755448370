import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// This is an interceptor that will run before each request to
//  add the access token to the Authorization header.
axiosInstance.interceptors.request.use(

    (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// This is an interceptor that will run before each response to check if the
//  response status is 401 (Unauthorized). If it is, the user will be redirected
//  to the login page.
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            // Optionally, refresh the token here or redirect to login
            localStorage.removeItem("accessToken");
            window.location.href = "/login"; // Redirect to login page
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;

