import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Link,
    CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import Alert from '@mui/material/Alert';


const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginStatus, setLoginStatus] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
      const authToken = localStorage.getItem('accessToken'); // Check if user is authenticated
      if (authToken) {
        navigate('/'); // Redirect to main page
      }
    }, [navigate]);


    const theme = createTheme({
        palette: {
            customGray: {
                main: '#49454F',
            },
        },
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoginStatus(null);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/token`,
                new URLSearchParams({
                    grant_type: "password",
                    username,
                    password,
                    scope: "",
                    client_id: "",
                    client_secret: "",
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Accept: "application/json",
                    },
                }
            );
            console.log("Login successful:", response.data);
            const accessToken = response.data.access_token;
            console.log("Access Token:", accessToken);

            // Store token in localStorage or sessionStorage
            localStorage.setItem("accessToken", accessToken);

            // Redirect to the home page
            window.location.href = "/";
        } catch (err) {
            console.error("Login failed:", err);
            setLoginStatus({
                status: 'error',
                message: 'Invalid username or password.',
              });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f5f5f5",
            }}
        >

            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: "#e9e9ee",
                    maxWidth: 700,
                    width: "100%",
                }}
            >
                <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
                    Log in
                </Typography>
                <form onSubmit={handleLogin} style={{ padding: '0 50px' }}>
                    <ThemeProvider theme={theme}>

                        {/* Display Status Message */}
                        {loginStatus && (
                            <Alert severity={loginStatus.status} sx={{ my: 2 }}>
                                {loginStatus.message}
                            </Alert>
                        )}

                        <TextField
                            label="Username"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="username"
                            placeholder="Type here"
                            variant="filled"
                            color="customGray"
                            sx={{
                                '& .MuiFilledInput-root': {
                                    backgroundColor: 'white',
                                    '&:hover': { backgroundColor: 'white' },
                                    '&.Mui-focused': { backgroundColor: 'white' },
                                },
                                mt: 5
                            }}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                            placeholder="Type here"
                            variant="filled"
                            color="customGray"

                            sx={{
                                '& .MuiFilledInput-root': {
                                    backgroundColor: 'white',
                                    '&:hover': { backgroundColor: 'white' },
                                    '&.Mui-focused': { backgroundColor: 'white' },
                                },
                                mt: 5
                            }}
                        />
                       
                        <Box
                            sx={{
                                textAlign: "right",
                            }}
                        >
                            <Link href="#" underline="hover" sx={{ fontSize: 14, color: '#49454F' }}>
                                Forgot Password?
                            </Link>

                        </Box>

                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    borderRadius: 50,
                                    minWidth: 80,
                                    height: 40,
                                    px: 4,
                                    mt: 3,
                                    background: '#fff',
                                    color: '#000',
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold',

                                }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : "Enter"}
                            </Button>
                        </Box>
                    </ThemeProvider>
                </form>
            </Paper>
        </Box>
    );
};

export default LoginPage;
