import React from 'react';
import { Container, Stack, Typography, Link, Avatar } from '@mui/material';

const ErrorPage = ({mainHeading="404", subHeading="Sorry the system couldn't find what you were looking for."}) => {
  return (
<Container
  fixed
  sx={{
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', 
  }}
>
  {/* Content */}
  <Stack spacing={2} alignItems="center" style={{ paddingBottom: '50px', color: '#515152' }}>
    <Typography variant="h1" fontSize="150px" fontWeight="bold">
      {mainHeading}
    </Typography>
    <Typography variant="body1" fontSize="18px">
      {subHeading}
    </Typography>

    <Typography variant="body1" fontWeight="bold">
    <Link href="/" variant="body1" fontWeight="bold" color='textSecondary'>
      Click here 
    </Link> to go back to the main screen
    </Typography>
  </Stack>
</Container>

  );
};

export default ErrorPage;
